import { LOADING_STATISTICS, FETCH_STATISTICS, LOADED_STATISTICS } from "../../constants";

export default (statistics = {oldText: 0, oldImg: 0, textStatistics: 0, imgStatistics: 0, loading: false }, action) => {
  switch (action.type) {
    case LOADING_STATISTICS:
      return { ...statistics, loading: true };
    case FETCH_STATISTICS:
      let newSt = Object.assign(statistics)
      if (action.payload?.total_texts_generated !== statistics?.textStatistics) {
        newSt = {...newSt, oldText:statistics?.textStatistics, textStatistics: action.payload?.total_texts_generated}
      }
      if (action.payload?.total_images_generated !== statistics?.imgStatistics) {

        newSt = {...newSt, oldImg:statistics?.imgStatistics, imgStatistics: action.payload?.total_images_generated}
      }
      return {...newSt, loading: false };
    case LOADED_STATISTICS:
        return { ...statistics, loading: false };
    default:
      return statistics;
  }
};
