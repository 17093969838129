import { LOADING, STOP_LOADING } from "../../constants";
import axios from "axios";

export default (loading = true, action) => {
  switch (action.type) {
    case LOADING:
        return true;
    case STOP_LOADING:
        return false;
    default:
      return loading;
  }
};
