import { LOADING_SINGLE_BLOG, FETCH_SINGLE_BLOG, LOADED_SINGLE_BLOG } from "../../constants";

export default (blogDetail = { blogDetail: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_SINGLE_BLOG:
            return { ...blogDetail, blogDetail: action.payload, loading: false };
        case LOADED_SINGLE_BLOG:
            return { ...blogDetail, loading: true };
        case LOADING_SINGLE_BLOG:
            return { ...blogDetail, loading: false };
        default:
            return blogDetail;
    }
}
