import { LOADING_BLOG, FETCH_BLOG, LOADED_BLOG } from "../../constants";

export default (blog = { blog: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_BLOG:
            return { ...blog, blog: action.payload, loading: false };
        case LOADED_BLOG:
            return { ...blog, loading: true };
        case LOADING_BLOG:
            return { ...blog, loading: false };
        default:
            return blog;
    }
}
