export const API_URL = (endpoint) => {
  return process.env.REACT_APP_BASE_URL + "/" + endpoint;
};

export const LOAD_USER = "LOAD_USER";

export const LOADING = "LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const USER_SUBSCRIPTION = "USER_SUBSCRIPTION";
export const LOADING_STATISTICS = "LOADING_STATISTICS";
export const FETCH_STATISTICS = "FETCH_STATISTICS";
export const LOADED_STATISTICS = "LOADED_STATISTICS";

export const LOADING_BLOG = "LOADING_BLOG";
export const FETCH_BLOG = "FETCH_BLOG";
export const LOADED_BLOG = "LOADED_BLOG";

export const LOADING_SINGLE_BLOG = "LOADING_SINGLE_BLOG";
export const FETCH_SINGLE_BLOG = "FETCH_SINGLE_BLOG";
export const LOADED_SINGLE_BLOG = "LOADED_SINGLE_BLOG";
const APP_NAME = process.env.REACT_APP_NAME;

const variables = {
  araby: {
    appName: "Araby AI",
    appShortName: "Araby.AI",
    gpt: "Araby GPT",
    lang: "Arabic",
    name: "araby",
    capsName: "ARABY",
  },
  xdream: {
    appName: "Xdream AI",
    appShortName: "Xdream.AI",
    gpt: "Xdream GPT",
    lang: "English",
    name: "xdream",
    capsName: "XDREAM",
  },
};

let appVariant;

if (APP_NAME === "Araby") {
  appVariant = variables.araby;
} else if (APP_NAME === "Xdream") {
  appVariant = variables.xdream;
} else {
  appVariant = variables.araby;
}

export { appVariant };
