import { combineReducers } from "redux";
import userAuth from "./user/userAuth";
import loading from "./loading/loading";
import statistics from "./statistics/statistics";
import userSubscription from './user_subscription';
import blog from './blog/blog';
import blogDetail from './blogDetail/blogDetail';

export default combineReducers({
  userAuth,
  loading,
  statistics,
  userSubscription,
  blog,
  blogDetail
});
