import { LOAD_USER } from "../../constants";
import axios from "axios";

export default (users = null, action) => {
  switch (action.type) {
    case LOAD_USER:
      return action.payload;
    case "AUTH":
      localStorage.setItem("sess", action.data);
      window.location.replace(process.env.REACT_APP_DASHBOARD_URL+"?t=" + action.data);
      axios.defaults.headers.common["Authorization"] = `${action.data}`;
      axios.defaults.headers.common["lang"] = JSON.parse(localStorage.getItem("language"))?.lang === 'arab'? 'ar':'en';
      // window.location.reload();
    default:
      return users;
  }
};
